import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby';
import Aboutme from '../components/Aboutme'
const Home=({data, location}) =>{
  const siteTitle = data.site.siteMetadata.title;
 return ( <Layout location={location} title={siteTitle}>
              <SEO title={`Home`} />
             <Aboutme/>
           </Layout>
 )
}
export default Home;
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }`