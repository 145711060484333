import React from "react"
import styled from "styled-components"
import { IoDocumentTextOutline } from "react-icons/io5"
import { FaWhatsapp } from "react-icons/fa"
import { useColorMode } from "theme-ui"

const SkillData = [
  {
    name: "TypeScript,JavaScript(ES)",
    image: "",
  },
  {
    name: "NodeJS,WebRTC",
    image: "",
  },
  {
    name: "ElectronJS",
    image: "",
  },
  {
    name: "ReactJS,Angular,AngularJS",
    image: "",
  },
  {
    name: "React Native,Ionic,Capcitor",
    image: "",
  },
  {
    name: "HTML & CSS",
    image: "",
  },
  {
    name: "MongoDB,Redis,Firebase",
    image: "",
  },
  {
    name: "npm,webpack",
    image: "",
  },
  {
    name: "AWS,Digital Ocen,Dokcer",
    image: "",
  },
  {
    name: "VS Code,Xcode,Android Studio",
    image: "",
  },
  {
    name: "Raspberry Pi(IOT),AI,AR",
    image: "",
  },
]

const OpenResume = () => {
  let ShareUrl = `https://drive.google.com/file/d/1fOSfah7EV4pqRQhH4hoN6CC5Ej_84vui/view?usp=sharing`
  window.open(ShareUrl)
}
const WhatSppME = () => {
  let ShareUrl = `https://api.whatsapp.com/send?phone=+918679778910&text=Hi Manoj I found You On Manojbhardwaj Blog`
  window.open(ShareUrl)
}

const GetYearDif = () => {
  let year = new Date().getFullYear()
  let diff = year - 2017
  return ` ${diff} `
}

const Aboutme = () => {
  const [colorMode] = useColorMode()
  return (
    <Contianer>
      <Autogrid>
        <Portfolcontiner>
          <Heading>Mobile Full Stack Developer - IDS Company</Heading>
          <LableText textcolor={colorMode === "dark" ? "#37e8ff" : "#e91e63"}>
            From Himachal Pradesh (Dharamshala) ,India{" "}
          </LableText>
          <LableTextDes>
            I am a professional Mobile and web developer with hands-on
            experience in HTML5, CSS3, Angular, NodeJS, React, React Native,
            Ionic I have been working with Mobile App development over the past
            {GetYearDif()}
            years. I am truly passionate about this wonderful library and
            Frameworks. Mobile, Web and Desktop App development is my thing and
            if you need any help regarding Mobile, Web and Desktop App, feel
            free to contact me through stack over overflow anytime.
          </LableTextDes>
          <Portfolioncvcontiner>
            <ButtonPort
              onClick={WhatSppME}
              textcolor={"#fff"}
              bgcolor={"#4CAF50"}
            >
              <FaWhatsapp size={22} style={{ margin: 5 }} />
              WhatsApp Me
            </ButtonPort>
            <ButtonPort
              onClick={OpenResume}
              textcolor={colorMode === "dark" ? "#1d4354" : "#fff"}
              bgcolor={colorMode === "dark" ? "#fff" : "#1d4354"}
            >
              <IoDocumentTextOutline
                color={colorMode === "dark" ? "#1d4354" : "#fff"}
                size={22}
                style={{ margin: 5 }}
              />
              View Resume
            </ButtonPort>
          </Portfolioncvcontiner>
        </Portfolcontiner>
        <PortfolcontinerImg>
          <Img
            alt="ManojBhardwaj"
            src={require("../assets/images/Manoj.jpeg")}
          />
        </PortfolcontinerImg>
      </Autogrid>

      <WhatiDO>
        <HeadingWhtido>What i do</HeadingWhtido>
        <LableTextDes>
          {`Full knowledge of iOS and Android Development Concept, Libraries, SDKs and programming patterns.
  Skills and Experience:
- RESTful APIs consumption and JSON parsing
- Custom UI views
- Custom animation
- Built to work well on multiple Android devices (Different screen sizes)
- AppStore release and tips on how to prevent the app to be rejected
- Social networks API (Facebook, Google, Twitter, YouTube, etc), use Android SDK (Camera, Phone, Contacts, Messaging, Power, Storage, Notifications, Web browser, Sockets)`}
        </LableTextDes>
      </WhatiDO>

      <AutogridSkill>
        {SkillData.map(({ name }, index) => {
          return <HeadingSkills key={index}>{name}</HeadingSkills>
        })}
      </AutogridSkill>
    </Contianer>
  )
}

export default Aboutme

const Contianer = styled.div``

const Heading = styled.strong`
  font-size: 22px;
`
const HeadingWhtido = styled.strong`
  font-size: 22px;
`
const HeadingSkills = styled.strong`
  font-size: 18px;
`
const LableTextDes = styled.p`
  font-size: 16px;
  font-weight: 500;
  white-space: pre-wrap;
`
const LableText = styled.p`
  color: ${prop => (prop.textcolor ? prop.textcolor : "")};
  font-size: 18px;
  font-weight: bold;
`
const Portfolcontiner = styled.div``
const PortfolcontinerImg = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`

const Portfolioncvcontiner = styled.div`
  display: flex;
  margin: 5px;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`

const WhatiDO = styled.div``

const Autogrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
const AutogridSkill = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-gap: 1rem;
`
const Img = styled.img`
  width: 300px;
  margin: 5px;
  border-radius: 150px;
  @media screen and (max-width: 768px) {
    width: 220px;
  }
`
const ButtonPort = styled.button`
  background: ${prop => (prop.bgcolor ? prop.bgcolor : "")};
  color: ${prop => (prop.textcolor ? prop.textcolor : "")};
  padding: 6px;
  font-weight: bold;
  border: 0px;
  align-items: center;
  display: flex;
  margin: 6px;
  border-radius: 10px;
  :focus {
    border: none;
    outline: none;
  }
`
